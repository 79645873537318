import React, { useState, useEffect, useCallback } from "react";
import useAxios from "../../hooks/useAxios";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  useToast,
  Button,
  Text,
  Input,
} from "@chakra-ui/react";

interface CepData {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export const APIViaCepMolecule = () => {
  const { response, loading, error, operation }: any = useAxios();
  const [cep, setCep] = React.useState<
    string | number | readonly string[] | undefined
  >();
  const [novoCep, setNovoCep] = React.useState<boolean>(false);
  const toast = useToast();

  const [isError, setisErrorInput] = useState<boolean>(false);

  const submit = () => {
    if (cep) {
      operation({
        //sempre colocar if para não fazer chamada desnecessária
        method: "GET",
        url: `${process.env.REACT_APP_VIA_CEP_URL}/${cep}/json/`,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      setNovoCep(true);
    } else {
      //caso não atenda aos critérios do input
      if (novoCep)
        alertToast(
          "CEP já foi digitado",
          "info",
          "Por favor, insira um novo CEP"
        );
      else
        alertToast(
          "CEP Inválido!!",
          "error",
          "Por favor, insira o CEP corretamente."
        );
    }
  };
  const alertToast = useCallback(
    (
      title: string,
      status: "error" | "info" | "warning" | "success" | undefined,
      description: string
    ) => {
      toast({
        title: title,
        description: description,
        status: status,
        duration: 9000,
        isClosable: true,
      });
    },
    [toast]
  );

  const handleChange = (event: any) => {
    if (event.target.value.length !== 8) setNovoCep(false);

    setCep(event.target.value);
    setisErrorInput(event.target.value.length !== 8);
  };

  useEffect(() => {
    if (response) {
      const responseData: any = response as CepData;
      if (responseData?.erro) {
        alertToast(
          "CEP Inválido!!",
          "error",
          "Por favor, insira o CEP corretamente."
        );
      } else {
        alertToast(
          "Busca efetuada",
          "success",
          "O CEP informado foi localizado"
        );
      }
      // setData(response);
    }
    return () => {
      setCep(undefined);
      setNovoCep(true);
    };
  }, [response, alertToast]);

  const isNumber = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  return (
    <FormControl isInvalid={isError}>
      <Grid templateColumns="repeat(5, 1fr)" gap={4}>
        <GridItem colSpan={2} h="10">
          <Text mb="8px">Informe o número do CEP:</Text>
          <FormControl isRequired>
            <FormLabel htmlFor="cepInput">CEP</FormLabel>
            <Grid templateColumns="repeat(2, 1fr)" gap={4}>
              <GridItem h="10">
                <Input
                  id="cepInput"
                  type="text"
                  isInvalid
                  focusBorderColor="blue"
                  errorBorderColor={!isError ? "lime" : "red.300"}
                  placeholder="Digite seu CEP"
                  value={cep}
                  onChange={handleChange}
                  maxLength={8}
                  minLength={1}
                />
                {!isError ? (
                  <FormHelperText>
                    Digite apenas números para liberar o botão de busca
                  </FormHelperText>
                ) : (
                  <>
                    <FormHelperText>
                      Inserir 8 números para buscar por CEP
                    </FormHelperText>
                    <FormErrorMessage>
                      Inserir 8 números para buscar por CEP
                    </FormErrorMessage>
                  </>
                )}
              </GridItem>
              <GridItem h="10">
                {!isError && cep && (
                  <>
                    {isNumber(cep) && (
                      <Button onClick={(e) => submit()} colorScheme="blue">
                        Buscar
                      </Button>
                    )}
                  </>
                )}
              </GridItem>
            </Grid>
          </FormControl>
        </GridItem>
        <GridItem colSpan={5}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {loading && <Text>Loading...</Text>}
          {error && !response && <Text>error</Text>}
          {/* {response && <pre>{JSON.stringify(data || response, null, 2)} </pre>} */}

          <Flex direction="column">
            {response && (
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={4}>
                  <Text fontSize="xl" fontWeight="bold" mb={2}>
                    Dados do CEP
                  </Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>CEP</FormLabel>
                    <Input
                      type="text"
                      value={response.cep}
                      placeholder="CEP"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>Logradouro</FormLabel>
                    <Input
                      type="text"
                      value={response.logradouro}
                      placeholder="Logradouro"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>Complemento</FormLabel>
                    <Input
                      type="text"
                      value={response.complemento}
                      placeholder="Complemento"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>Bairro</FormLabel>
                    <Input
                      type="text"
                      value={response.bairro}
                      placeholder="Bairro"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>Localidade</FormLabel>
                    <Input
                      type="text"
                      value={response.localidade}
                      placeholder="Localidade"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>UF</FormLabel>
                    <Input
                      type="text"
                      value={response.uf}
                      placeholder="UF"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>IBGE</FormLabel>
                    <Input
                      type="text"
                      value={response.ibge}
                      placeholder="IBGE"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>GIA</FormLabel>
                    <Input
                      type="text"
                      value={response.gia}
                      placeholder="GIA"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>DDD</FormLabel>
                    <Input
                      type="text"
                      value={response.ddd}
                      placeholder="DDD"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={1}>
                  <FormControl isDisabled>
                    <FormLabel>SIAFI</FormLabel>
                    <Input
                      type="text"
                      value={response.siafi}
                      placeholder="SIAFI"
                      mb={2}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
            )}
          </Flex>
        </GridItem>
      </Grid>
    </FormControl>
  );
};
