import { Box } from "@chakra-ui/react"
import { ReactElement } from "react"

export const HeaderTitle = (props: any): ReactElement => {
    return(
        <Box fontSize="96pt"
        position={{md: 'absolute'}}
        left="50%"
        transform="translateX(-50%)"
        top="169px"
        display="none"
        color="#00000014">{props.value}</Box>
    )
}