import { Flex, Text } from "@chakra-ui/react"
import { Images } from "../common/Images"

import ps from '../../shared/images/ps5.png';
import cats from '../../shared/images/cats.jpg';
import { ImageProps } from "../../interfaces/commom/interfaces";
import { H3Title } from "../common/Title/H3Title";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import { theme } from "../../shared/styles/theme";

const BobMiloImage: ImageProps = {
    src: cats,
    alt: 'Imagem de Destaque',
    title: 'Bob e Milo',
    description: 'Tomando aquele solzinho da tarde...'
}

const srcLizImage: ImageProps = {
    src: ps,
    alt: 'Imagem de Destaque'
}

export const HobbiesPetsImagesMolecule = () => {
    return(
        <Flex>
            <Flex flex='1' border="1px solid" flexDirection="column" borderColor={theme.colors.tertiary[600]} >
                <Images {...BobMiloImage} />
                <Text height="100%" background={theme.colors.tertiary[600]}>
                    <H3Title {...{title: BobMiloImage.title, color: theme.colors.default[100]}}></H3Title>
                    <SeparetorMarginBottom />
                    <Text >{BobMiloImage.description}</Text>
                    <SeparetorMarginBottom />
                </Text>
            </Flex>
            <Flex flex='1' border="1px solid" borderColor={theme.colors.tertiary[600]}>
                <Images {...srcLizImage} />
            </Flex>
        </Flex>
    )
}