import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { NavTextProps } from '../../interfaces/commom/interfaces';
import { SeparetorWhite } from './SeparetorWhite';

const navData: NavTextProps[] = [
  {
    title: 'Apresentação',
    url: '/',
  },
  {
    title: 'Formação',
    url: '/formacao',
  },
  { title: 'Experiências', url: 'experiencias' },
  { title: 'Testes', url: 'teste-unitario' },
];
//adicionando um novo objeto no array
const newNav = [{ title: 'API', url: 'api-viacep' }];

const Nav: React.FC<{}> = () => {
  const [nav, setNav] = React.useState<NavTextProps[]>(navData);

  //adicionando um novo objeto no array
  React.useEffect(() => {
    //necessário quando não existe evento. Por exemplo, um click pelo botão age de modo normal, sem o click ele cai em um loop infinito.
    //o que será executado quando
    //componente for criado
    //dependência executada.
    setNav((prevValue: NavTextProps[]) => [...prevValue, ...newNav]);
    return () => {
      //será executado quando o componente for destruído
    };
  }, []);

  return (
    <>
      {nav.map((element: NavTextProps, index: number) => (
        <Flex key={index}>
          <Link href={element.url}>
            <Text
              fontWeight="bold"
              fontSize={{ base: 'md', md: '1xl' }}
              padding="0 30px"
            >
              {element.title}
            </Text>
          </Link>
        </Flex>
      ))}
    </>
  );
};
export default Nav;