import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    default: {
      100: '#FFFFFF',     // Branco
      200: '#F1F1F1',     // Cinza claro
      600: '#747474',     // Cinza médio
      700: '#515151',     // Cinza médio-escuro
      800: '#333333',     // Cinza escuro
      900: '#000000',     // Preto
    },
    primary: {
      300: '#FF0080',     // Rosa
      600: '#7928CA',     // Roxo
    },
    second: {
      600: '#FF7E00',     // Laranja
    },
    tertiary: {
      600: '#00B2FF',     // Azul claro
    },
  },
});
