import { Container, Flex } from "@chakra-ui/react"
import { theme } from "../../shared/styles/theme"
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom"
import { FormationCertificationMolecule } from "../molecule/FormationCertificationMolecule"
import { TitleLineColors } from "../molecule/TitleLineColors"

export const FormationCertificationOrganism  = () => {
    return(
        <Flex flexDirection="column" background={theme.colors.default[100]} padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]}>
                <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={{ title: "Certificações" }} />
                <SeparetorMarginBottom {...{value: 12}} />
            </Container> 
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <SeparetorMarginBottom {...{value: 12}} />
                <FormationCertificationMolecule />
            </Container>
        </Flex>
    )
}