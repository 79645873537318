import { Container, Flex, Grid, GridItem } from "@chakra-ui/react"
import { theme } from "../../shared/styles/theme"
import { FamilyLocationDescriptionMolecule } from "../../modules/apresentacao/templates/familia/FamilyLocationDescriptionMolecule"
import { FamilyLocationImagesMolecule } from "../../modules/apresentacao/templates/familia/FamilyLocationImagesMolecule"

export const FamilyLocationOrganism = () => {
    return(
        <Flex flexDirection="column" background={theme.colors.default[200]} padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Grid templateColumns={{base:'repeat(1, 1fr)', md:'repeat(2, 1fr)'}} gap={69} width="100%" >
                    <GridItem w='100%' height="100%">
                        <Flex alignItems="center" height="100%">
                            <FamilyLocationImagesMolecule />
                        </Flex>
                    </GridItem>
                    <GridItem w='100%'>
                        <FamilyLocationDescriptionMolecule />
                    </GridItem>
                </Grid>
            </Container>
        </Flex>
    )
}