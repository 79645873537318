import { Container, Flex, Grid, GridItem } from "@chakra-ui/react"
import { theme } from "../../shared/styles/theme"
import { HobbiesPetsDescriptionMolecule } from "../molecule/HobbiesPetsDescriptionMolecule"
import { HobbiesPetsImagesMolecule } from "../molecule/HobbiesPetsImagesMolecule"

export const HobbiesPetsOrganism = () => {
    return(
        <Flex flexDirection="column" padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={69} width="100%">
                    <GridItem w='100%' height="100%">
                        <HobbiesPetsDescriptionMolecule />
                    </GridItem>
                    <GridItem w='100%'>
                        <Flex alignItems="center" height="100%">
                            <HobbiesPetsImagesMolecule /> 
                        </Flex>
                    </GridItem>
                </Grid>
            </Container>
        </Flex>
    )
}