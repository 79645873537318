import { Container, Flex, Grid, GridItem } from "@chakra-ui/react";
import { theme } from "../../shared/styles/theme";
import { PorpusesImagesMolecule } from "../molecule/PorpusesImagesMolecule";
import { PurposesDescriptionMolecule } from "../molecule/PurposesDescriptionMolecule";
import { TitleLineColors } from "../molecule/TitleLineColors";

export const PurposesOrganism = () => {
  return (
    <Flex
      flexDirection="column"
      background={theme.colors.default[100]}
      padding="9vw 0"
    >
      <Container
        maxW="1200px"
        color={theme.colors.default[100]}
        display="-webkit-inline-box"
      >
        <TitleLineColors
          marginTop="4vw"
          H1TitleEnginnerSofware={{ title: "Hype" }}
        />
      </Container>
      <Container
        maxW="1200px"
        color={theme.colors.default[100]}
        display="-webkit-inline-box"
      >
        <Grid templateColumns="repeat(2, 1fr)" gap={69} width="100%">
          <GridItem rowSpan={2} w="100%" height="100%">
            <Flex alignItems="center" height="100%">
              <PorpusesImagesMolecule />
            </Flex>
          </GridItem>
          <GridItem rowSpan={2} w="100%">
            <PurposesDescriptionMolecule />
          </GridItem>
        </Grid>
      </Container>
    </Flex>
  );
};
