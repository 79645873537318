import { Box, Image } from '@chakra-ui/react';
import { ImageProps } from '../../interfaces/commom/interfaces';

export const Images = ({...props}: ImageProps) => {
    return(
        <Box width='100%'>
            <Image 
                width='100%' 
                height="100%"
                objectFit="cover"
                minHeight="274px"
                src={props.src} alt={props.alt} />
        </Box>
    )
}