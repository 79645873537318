import { Text, Flex } from "@chakra-ui/react"
import { IExperiencesCertificate } from "../../../interfaces/commom/interfaces"
import { theme } from "../../../shared/styles/theme"
import Description from "../../common/Description/Description"
import Title from "../../common/Title/Title"

export const ExperiencesCertificateDescriptionMolecule = ({ ...props }: IExperiencesCertificate) => {
    return (
        <Flex padding="12px 48px" flexDirection="column" height="188px" background={theme.colors.default[200]} color="black">
            <Title {...{ title: props.title, as: 'h2', size: 'xl' }} />
            <Text>{props.year.initial}-{props.year.final}</Text>
            <Description {...{ description: props.description }} />
        </Flex>
    )
}