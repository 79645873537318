import { HomeTemplate } from "../components/template/HomeTemplate";
import { Footer } from "../components/organism/Footer";

export const Home = () => {
  return (
    <>
      <HomeTemplate />
      <Footer />
    </>
  );
};
