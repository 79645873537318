import React from 'react';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

// Função para gerar uma duração aleatória entre 4 e 7 segundos
const getRandomDuration = () => Math.random() * 3 + 4;

const CircuitBackground = () => {
  const lines = Array.from({ length: 108 }, (_, i) => i * 108);

  return (
    <Box position="absolute" top={0} left={0} width="100%" height="100%" zIndex={-1} overflow="hidden">
      <svg width="100%" height="100%">
        {lines.map((x, index) => {
          // Gerar uma duração aleatória para cada retângulo
          const duration = getRandomDuration();
          const animation = {
            y: index % 2 === 0 ? ['100%', '-100%'] : ['-100%', '100%'],
            transition: {
              duration,
              repeat: Infinity,
              repeatDelay: 22,
              ease: 'linear'
            }
          };

          return (
            <g key={x}>
              <line x1={x} y1="0" x2={x} y2="100%" stroke="#cccccc22" strokeWidth="2" />
              <motion.rect
                x={x - 5}
                width="10"
                height="30"
                fill="#fedf55"
                animate={animation}
              />
            </g>
          );
        })}
      </svg>
    </Box>
  );
};

export default CircuitBackground;
