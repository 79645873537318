import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AboutMe } from '../pages/AboutMe';
import { Experiences } from '../pages/Experiences';
import { Formation } from '../pages/Formation';
import { Home } from '../pages/home';
import CoveragePage from '../pages/Coverage';
import NotFound from '../pages/NotFound';

export const RoutesDom = () => {
  return (
    <div data-testid="routes-dom">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="*" element={<NotFound />}></Route>
          <Route path="/formacao" element={<Formation />}></Route>
          <Route path="/experiencias" element={<Experiences />}></Route>
          <Route path="/api-viacep" element={<AboutMe />}></Route>
          <Route path="/teste-unitario" element={<CoveragePage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
