import { Flex, Image } from '@chakra-ui/react'

import { NumbersVerticaly } from "../../common/NumbersVerticaly"
import { theme } from '../../../shared/styles/theme';
import { IExperiencesCertificate } from '../../../interfaces/commom/interfaces';

export const ExperiencesCertificateImageMolecule = ({...props}: IExperiencesCertificate) => {
    const order: any = props.order
    return(
        <Flex height="150px" width="100%" background={theme.colors.default[200]}>
            <NumbersVerticaly props={order} />
            <Flex width="80%" justifyContent="center" background={theme.colors.default[100]} >
                <Image src={props.image} alt={props.title} />
            </Flex>
        </Flex>
    )
}