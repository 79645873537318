import { Flex } from "@chakra-ui/react"
import { Images } from "../common/Images"

import code from '../../shared/images/code.jpg';
import esg from '../../shared/images/esg.jpg';
import download from '../../shared/images/download.jpg';
import { ImageProps } from "../../interfaces/commom/interfaces";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import { theme } from "../../shared/styles/theme";

const codeImage: ImageProps = {
    src: code,
    alt: 'Imagem de Destaque'
}
const esgImage: ImageProps = {
    src: esg,
    alt: 'Imagem de Destaque'
}

const downloadImage: ImageProps = {
    src: download,
    alt: 'Imagem de Destaque'
}

export const PorpusesImagesMolecule = () => {
    return(
        <Flex flexDirection={{ base: "column", md: "row" }}>
            <Flex flex='1' border='2px solid' borderColor={theme.colors.tertiary[600]}  bg={theme.colors.tertiary[600]} flexDirection="column" >
                <Images {...downloadImage} />
                <SeparetorMarginBottom {...{value: 1}}/>
                <Images {...esgImage} />
            </Flex>
            <Flex flex='1' border="1px solid" borderColor={theme.colors.tertiary[600]}>
                <Images {...codeImage} />
            </Flex>
        </Flex>
    )
}