import { Container, Flex, Grid, GridItem } from "@chakra-ui/react"
import { ExperiencesCertificateDescriptionMolecule } from "../molecule/Experiences/ExperiencesCertificateDescriptionMolecule"
import { ExperiencesCertificateImageMolecule } from "../molecule/Experiences/ExperiencesCertificateImageMolecule"

import { theme } from "../../shared/styles/theme"
import impacta from '../../shared/images/impacta.jpg';
import alura from '../../shared/images/alura.png';
import fiap from '../../shared/images/fiap.png';
import esalq from '../../shared/images/usp-esalq.png';
import { TitleLineColors } from "../molecule/TitleLineColors";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import { IExperiencesCerficateResult, IExperiencesCertificate } from "../../interfaces/commom/interfaces";


const experiencesCertificate: IExperiencesCerficateResult = { 
    title: 'Certificações',
    result: [
        {
            title: 'USP/ESALQ',
            order: 1, 
            image: esalq, 
            description: 'Cursando Engenharia de Sofware.',
            year: { initial: '2024', final: '2026' }
        },
        {
            title: 'FIAP',
            order: 2, 
            image: fiap, 
            description: 'Formado em Sistemas para Internet.',
            year: { initial: '2021', final: '2023' }
        },
        {
            title: 'Alura',
            order: 3, 
            image: alura, 
            description: 'Single Page Application.',
            year: { initial: '2015', final: '2020' },
        },
        {   
            title: 'Impacta',
            order: 4, 
            image: impacta,  
            description: 'SQL Server 2008, XHTML, CSS, .NET.',
            year: { initial: '2011', final: '2012' }
        }
    ]
}

export const ExperiencesCertificateOrganism = () => {
    return(
        <Flex flexDirection="column" padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]}>
                <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={{ title: "Certificações" }} />
                <SeparetorMarginBottom {...{value: 12}} />
            </Container> 
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Grid
                    h='auto'
                    templateColumns='repeat(3, 1fr)'
                    gap={8}
                    >
                        {experiencesCertificate.result.map((element: IExperiencesCertificate) => (
                            <GridItem>
                                <Flex flexDirection="column">
                                    <ExperiencesCertificateImageMolecule {...element} />
                                    <ExperiencesCertificateDescriptionMolecule {...element} />
                                </Flex>
                            </GridItem>
                        ))}
                </Grid>
            </Container>
            <SeparetorMarginBottom {...{value: 12}} />
        </Flex>
    )
}