import { Heading, HeadingProps } from "@chakra-ui/react";
import { TitleProps } from "../../../interfaces/commom/interfaces";
import { theme } from "../../../shared/styles/theme";


const H1Title: React.FC<TitleProps & HeadingProps> = ({ size, as, ...props }) => {
    return (
        <Heading
            lineHeight="80px"
            as={as} // Define o elemento HTML com base na propriedade 'as', ou usa "h1" como padrão
            color={props.color ?? theme.colors.default[900]}
            size={size} // Define o tamanho com base na propriedade 'size', ou usa "2xl" como padrão
            textTransform="uppercase"
        >
            {props.title}
        </Heading>
    );
};

export default H1Title