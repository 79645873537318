import { Text } from '@chakra-ui/react'
import { ItensProps, listItensProps } from '../../interfaces/commom/interfaces'
import { theme } from '../../shared/styles/theme';

export const NumbersPipe = (listItens: listItensProps) => {
    return(
        <>
            {listItens.itens.map((element: ItensProps, key: number) => (
                <Text paddingBottom="9px" key={key} as="p" color={theme.colors.default[900]} fontSize='lg'><Text as="span" fontWeight="bold" color={theme.colors.primary[300]}>{element.order} |</Text>  {element.description} <br /></Text> 
            ))}
        </>
    )
}