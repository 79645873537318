import { Flex } from "@chakra-ui/react"
import Nav from "../common/Nav"
import { SeparetorWhite } from "../common/SeparetorWhite"


export const NavMolecule = () => {
  return (
    <Flex as="ul" flexDirection="row-reverse" alignItems="center" h="100%">
      <Flex as="li">
      
        <Nav />
      </Flex>
    </Flex>
  )
}