import { Flex, Container } from "@chakra-ui/react"
import { ImageProps } from "../../interfaces/commom/interfaces"
import { Images } from "../common/Images"
import { TitleLineColors } from "../molecule/TitleLineColors"
import navFeature from '../../shared/images/nav-feature.png';
import { theme } from "../../shared/styles/theme";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";

const srcImages: ImageProps = {
    src: navFeature,
    alt: 'Imagem de Destaque'
}

const H1TitleEnginnerSofware = { title: "Engenheiro de Software"}
export const Header = () => {
    return(
        <Flex flexDirection="column">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={H1TitleEnginnerSofware} />
            </Container>
            <SeparetorMarginBottom {...{value: 40}}/>
            <Images {...srcImages} />
            <SeparetorMarginBottom {...{value: 40}}/>
        </Flex>
    )
}