import { Container, Flex } from "@chakra-ui/react";
import { theme } from "../../shared/styles/theme";
import Description from "../common/Description/Description";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import { TitleLineColors } from "../molecule/TitleLineColors";

export const FormationDescription = () => {
  return (
    <Flex
      flexDirection="column"
      padding="9vw 0"
    >
      <Container
        maxW="1200px"
        color={theme.colors.default[100]}
        display="-webkit-inline-box"
      >
        <Flex flexDirection="column">
          <TitleLineColors
            marginTop="4vw"
            H1TitleEnginnerSofware={{ title: "Aprendizado" }}
          />
          <Description
            {...{
              description:
                "Desenvolvimento Front-end: HTML, CSS, JavaScript, HTML5, Angular, Gulp, Bootstrap, Ruby, React, Sass. Desenvolvimento de aplicações responsivas.",
            }}
          />
          <Description
            {...{
              description:
                "E-Commerce & E-Business: Desenvolvimento de marketplaces. Conhecimento profundo em comércio eletrônico. Modelos de negócios para e-commerces. Jornada do consumidor. Estratégias de divulgação de comércio eletrônico. Empreendedorismo na internet. Boas práticas de desenvolvimento para e-commerce.",
            }}
          />
          <Description
            {...{
              description:
                "Total Experience: User Experience (UX) e Customer Experience (CX). Navegação intuitiva. Design de interfaces digitais para e-commerces, sites e aplicativos.",
            }}
          />
          <Description
            {...{
              description:
                "Database & Data Science: Modelagem de banco de dados. Análise de dados.",
            }}
          />
          <Description
            {...{
              description:
                "Cloud Computing: Microsoft Azure.",
            }}
          />
          <Description
            {...{
              description:
                "Desenvolvimento de Aplicações: Desenvolvimento de aplicações variadas com Java.                ",
            }}
          />
          <Description
            {...{
              description:
                "Marketing Digital & Social Media: Gestão de conteúdo em redes sociais. Marketing de conteúdo. Compreensão dos algoritmos do Instagram/Facebook. SEO para otimização de mecanismos de busca.",
            }}
          />
          <Description
            {...{
              description:
                "Desenvolvimento de Apps Mobile: Android e iOS.",
            }}
          />
          <Description
            {...{
              description:
                "Inteligência Artificial & Machine Learning: Desenvolvimento de soluções inovadoras. Chatbots e outras aplicações.",
            }}
          />
          <Description
            {...{
              description:
                "Gestão de Negócios: Scrum e metodologias ágeis. Empreendedorismo profissional. Criação de startups no projeto Startup One.",
            }}
          />
          <Description
            {...{
              description:
                "",
            }}
          />
        </Flex>
      </Container>
    </Flex>
  );
};
