import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';

import { createRoot } from 'react-dom/client';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  // Now you can use `root` to render your React app
  root.render(
    <ChakraProvider>
      <ColorModeScript />
      <App />
    </ChakraProvider>
  );
} else {
  console.error('Root element with id "root" not found.');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
