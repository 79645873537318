import { Container, Flex, Grid, GridItem } from "@chakra-ui/react"
import { Images } from "../common/Images"
import { TiagoSilvaLeftMolecule } from "../../modules/apresentacao/templates/tiago/TiagoSilvaLeft"

import tiag from '../../shared/images/tiag188.jpg';
import { ImageProps } from "../../interfaces/commom/interfaces";
import { theme } from "../../shared/styles/theme";

const srcImages: ImageProps = {
    src: tiag,
    alt: 'Imagem de Destaque'
}

export const TiagoSilva = () => {
    return(
        <Flex flexDirection="column">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Grid templateColumns='repeat(2, 1fr)' gap={69} width="100%" padding="9vw 0">
                    <GridItem w='100%'>
                        <TiagoSilvaLeftMolecule />
                    </GridItem>
                    <GridItem w='100%'>
                        <Flex>
                            <Images {...srcImages} /> 
                        </Flex>
                    </GridItem>
                </Grid>
            </Container>
        </Flex>
        
    )
}