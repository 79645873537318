import { Text, TextProps } from '@chakra-ui/react';
import React from 'react';

export interface CircleNumberProps {
  order: number;
}

const CircleNumber: React.FC<CircleNumberProps & TextProps> = ({
  order,
  ...rest
}) => {
  return (
    <Text
      height="37px"
      as="span"
      bg="#1A9988"
      color="white"
      padding="6px 13px"
      borderRadius="19px"
      fontWeight="bold"
      {...rest}
    >
      {order}
    </Text>
  );
};

export default CircleNumber;
