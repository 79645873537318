import { Footer } from "../components/organism/Footer"
import { ExperiencesTemplate } from "../components/template/ExperiencesTemplate"

export const Experiences = () => {
    return(
        <>
            <ExperiencesTemplate />
            <Footer />
        </>
    )
}