import { Text, Flex, Link } from "@chakra-ui/react"
import { theme } from "../../shared/styles/theme"

export const Footer = () => {
    const today = new Date().getFullYear();
    return (
        <Flex width="100%" bg={theme.colors.primary[600]} padding="2vw 0">
            <Text width="100%" textAlign="center" color="white">
                <Link target="_blank" href="https://tiag.com.br">Tíäg Silvä</Link> | 2008 - {today} &copy; Todos os direitos Reservados
            </Text>
        </Flex>
    )
}