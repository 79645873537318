import React from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { DescriptionProps } from '../../../interfaces/commom/interfaces';
import { theme } from '../../../shared/styles/theme';

const Description: React.FC<DescriptionProps & TextProps> = ({
  description,
  ...rest
}) => {
  return (
    <div data-testid="description-text" style={{ width: '100%', height: 'auto', minHeight: '8vh' }}>
      <Text as="p" fontSize="lg" color={theme.colors.default[900]} {...rest}>
        <span dangerouslySetInnerHTML={{ __html: description ?? '' }} />
      </Text>
    </div>
  );
};

export default Description;
