import { List, ListItem,Text  } from "@chakra-ui/react"
import { AbilityProps } from "../../../interfaces/commom/interfaces"

export const ExperiencesAbilityListMolecule = (props: any) => {
    return(
        <List spacing={3}>
            {props.itens.map((element: AbilityProps, key: number) => (
                <ListItem key={key}>
                    <Text fontSize="16pt">{element.ability} ({element.rank})</Text>
                </ListItem>
            ))}
        </List>
    )
}