import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Container,
  Grid,
  GridItem,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Link,
  Box,
  HStack,
  Spacer,
  Image,
} from '@chakra-ui/react';
import { theme } from '../../shared/styles/theme';
import { Site } from '../common/Site';
import { NavMolecule } from '../molecule/NavMolecule';
import { motion } from 'framer-motion';
import { ImageProps } from '../../interfaces/commom/interfaces';
import coverageImg from '../../shared/images/egenheiro-de-software.jpg';

export const HeaderNav = () => {
  const srcCoverage: ImageProps = {
    src: coverageImg,
    alt: 'Imagem de Destaque',
  };


  return (
    <>
      <motion.header
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Flex
          w="100%"
          h="80px"
          bgGradient="linear(to-r, #FF0080, #7928CA)"
          
        >

          <Spacer />
          <HStack spacing={4} >
            {/* Aqui você pode adicionar os ícones das mídias sociais */}
            <a href="https://github.com/tiag188" target='_blank'>
              <Box w="33px" h="100%" bg="white" mr="22px" borderRadius={22}>
                <svg height="32" aria-hidden="true" viewBox="0 0 16 16" version="1.1" width="32" data-view-component="true" >
                  <path d="M8 0c4.42 0 8 3.58 8 8a8.013 8.013 0 0 1-5.45 7.59c-.4.08-.55-.17-.55-.38 0-.27.01-1.13.01-2.2 0-.75-.25-1.23-.54-1.48 1.78-.2 3.65-.88 3.65-3.95 0-.88-.31-1.59-.82-2.15.08-.2.36-1.02-.08-2.12 0 0-.67-.22-2.2.82-.64-.18-1.32-.27-2-.27-.68 0-1.36.09-2 .27-1.53-1.03-2.2-.82-2.2-.82-.44 1.1-.16 1.92-.08 2.12-.51.56-.82 1.28-.82 2.15 0 3.06 1.86 3.75 3.64 3.95-.23.2-.44.55-.51 1.07-.46.21-1.61.55-2.33-.66-.15-.24-.6-.83-1.23-.82-.67.01-.27.38.01.53.34.19.73.9.82 1.13.16.45.68 1.31 2.69.94 0 .67.01 1.3.01 1.49 0 .21-.15.45-.55.38A7.995 7.995 0 0 1 0 8c0-4.42 3.58-8 8-8Z"></path>
                </svg>
              </Box>
            </a>
          </HStack>
        </Flex>
      </motion.header>
      <motion.header
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Flex
          background={theme.colors.default[800]}
          height="49px"
          display={{ base: 'none', md: 'flex' }}
        >
          <Container
            maxW="100%"
            color={theme.colors.default[100]}
            display="-webkit-inline-box"
          >
            <Grid templateColumns="repeat(3, 1fr)" width="100%" gap={69}>
              <GridItem width="100%">
                {/* <Site /> */}
              </GridItem>
              <GridItem colSpan={2} width="100%">
                <NavMolecule />
              </GridItem>
            </Grid>
          </Container>
        </Flex>
        <Flex
          display={{ base: 'flex', md: 'none' }}
          padding="15px"
          flexDirection="row-reverse"
        >
          <Menu isLazy>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem>
                <Link href="/">Apresentação</Link>
              </MenuItem>
              <MenuItem>
                <Link href="/formacao">Formação</Link>
              </MenuItem>
              <MenuItem>
                <Link href="/experiencias">Experiências</Link>
              </MenuItem>
              <MenuItem>
                <Link href="/api-viacep">API ViaCEP</Link>
              </MenuItem>
              <MenuItem>
                <Link href="/teste-unitario">Teste unitário</Link>
              </MenuItem>
            </MenuList>
          </Menu>

        </Flex>
      </motion.header>
    </>
  );
};
