import { Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { theme } from "../../shared/styles/theme";
import CircleNumber from "../common/CircleNumber/CircleNumber";

export interface FormationCertificationItensProps {
  order: number;
  description: string;
}
export interface FormationCertificationProps {
  itens: FormationCertificationItensProps[];
}

const formationCertificationData: FormationCertificationProps = {
  itens: [
    {
      order: 1,
      description:
        "Certificado de qualificação profissional em Responsive Web - Front End e User Experience",
    },
    {
      order: 2,
      description:
        "Certificado de qualificação profissional em Responsive Web - Front End e User Experience",
    },
    {
      order: 3,
      description:
        "Certificado de qualificação profissional em Digital Marketing, Social Media e SEO e IA e Machine Learning",
    },
    {
      order: 4,
      description:
        "Certificado de qualificação profissional de Desenvolvimento Mobile Híbrido (Android e iOS) e Back End",
    },
    {
      order: 5,
      description:
        "Certificado de qualificação profissional em FullStack Developer",
    },
  ],
};

export const FormationCertificationMolecule = () => {
  return (
    <Flex flexDirection="row">
      <Grid
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(2, 1fr)"
        gap={4}
      >
        {formationCertificationData.itens.map(
          (element: FormationCertificationItensProps, key: number) => (
            <GridItem
              key={key}
              padding={key === 0 ? "4% 19%" : "0px"}
              colSpan={key === 0 ? 2 : 1}
            >
              <Flex margin="1% 10%">
                <Flex w="10%">
                  <CircleNumber {...element} />
                </Flex>
                <Flex w="90%">
                  <Text
                    key={key}
                    fontSize="2xl"
                    color={theme.colors.default[900]}
                  >
                    {element.description}
                  </Text>
                </Flex>
              </Flex>
            </GridItem>
          )
        )}
      </Grid>
    </Flex>
  );
};
