import { Heading } from "@chakra-ui/react"
import { TitleProps } from "../../../interfaces/commom/interfaces";
import { theme } from "../../../shared/styles/theme";

export const H3Title = (props: TitleProps) => {
    return (
        <Heading
            as="h3"
            color={props?.color || theme.colors.default[900]}
            textAlign='center'
            fontSize='27'
            paddingTop="4"
            
        >
            {props.title || ''}
        </Heading>
    )
}