import { FormationDescription } from "../organism/FormationDescription";
import { FormationFiapOrganism } from "../organism/FormationFIAPOrganism";
import { HeaderNav } from "../organism/HeaderNav";
import { Image } from "@chakra-ui/react";
import BgMontain from "../../shared/images/bg-motain.jpg";
import { FormationCertificationOrganism } from "../organism/FormationCertificationOrganism";
import { HeaderTitle } from "../common/HeaderTitle";
import { TitlePageORganism } from "../organism/TitlePageOrganism";

export const FormationTemplate = () => {
  return (
    <>
      <HeaderNav />
      <HeaderTitle {...{ value: "Formação" }} />

      <TitlePageORganism {...{ value: "Formação" }} />
      <FormationFiapOrganism />
      <FormationDescription />
      <Image
        src={BgMontain}
        alt="Dan Abramov"
        height="220px"
        w="100%"
        objectFit="cover"
      />
      <FormationCertificationOrganism />
    </>
  );
};
