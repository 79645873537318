import { listItensProps } from "../../interfaces/commom/interfaces";
import Description from "../common/Description/Description";
import { NumbersPipe } from "../common/NumbersPipe";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";

const listItens: listItensProps = {
  itens: [
    {
      order: 1,
      description: "Melhora contínua das habilidades",
    },
    {
      order: 2,
      description: "Imersão diária em tecnologias avançadas",
    },
    {
      order: 3,
      description: "Expandir os contatos profissionais",
    },
    {
      order: 4,
      description: "Troca de aprendizado anual na USP",
    },
    {
      order: 5,
      description: "Contribuir para um mundo sustentável",
    },
  ],
};

export const PurposesDescriptionMolecule = () => {
  return (
    <>
      <SeparetorMarginBottom />
      <Description
        {...{
          description:
            "Nos próximos <b>2 anos</b>, pretendo continuar aperfeiçoando minhas habilidades de <b>Engenheiro de Software</b>, em um nível cada vez mais elevado. ",
        }}
      />
      <Description
        {...{
          description:
            "Elevar habilidades de <b>Empreendedorismo ESG</b> e aperfeiçoar a <b>liderança e marketing.</b>",
        }}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
      <NumbersPipe {...listItens} />
    </>
  );
};
