import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom"
import { TitleLineColors } from "../molecule/TitleLineColors"
import { Container, Flex, Heading } from "@chakra-ui/react"
import { ArrowRightIcon } from "@chakra-ui/icons"
import { theme } from "../../shared/styles/theme"

export const FormationFiapOrganism = () => {
    return(
        <Flex flexDirection="column" background={theme.colors.default[700]} padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Flex flexDirection="column">
                    <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={{ title: "Formação FIAP e USP", color: "white" }} />
                    <SeparetorMarginBottom {...{value: 12}} />
                    <Heading as='h3' size='lg' paddingBottom="30px"><ArrowRightIcon w={8} h={8} /> Cursando MBA em Engenharia de Sofware: USP.</Heading>
                    <Heading as='h3' size='lg' paddingBottom="30px"><ArrowRightIcon w={8} h={8} /> 1º ano, conclusão final de 2026.</Heading>
                    <Heading as='h3' size='lg' paddingBottom="30px"><ArrowRightIcon w={8} h={8} /> Formado em Sistemas para Internet: FIAP.</Heading>
                </Flex>
            </Container>
        </Flex>
    )
}