import { DescriptionProps, ProgressPercentualProps } from "../../../../interfaces/commom/interfaces"
import Description from "../../../../components/common/Description/Description"
import { ProgressPercentual } from "./ProgressPercentual"
import { SeparetorMarginBottom } from "../../../../components/common/SeparetorMarginBottom"
import { TitleLineColors } from "../../../../components/molecule/TitleLineColors"

const H1TitleEnginnerSofware = { title: "Tiago Silva" }

const description: DescriptionProps = {
    description: "Com mais de 18 anos de experiência em Tecnologia da Informação, incluindo 12 anos dedicados ao desenvolvimento de software.<br><br> Estou constantemente atualizado com as mais recentes práticas e tecnologias deste vasto e dinâmico universo."
}

export const progressPercentual: ProgressPercentualProps[] = 
  [
    {
      title: 'Metodologia Ágil',
      description: 'Expertise em implementação ágil de projetos',
      value: 97,
    },
    {
      title: 'Front-end',
      description: 'Domínio em criação de interfaces responsivas e interativas',
      value: 96,
    },
    {
      title: 'Back-end',
      description: 'Experiência sólida em arquitetura e desenvolvimento robusto de sistemas',
      value: 85,
    },
    {
      title: 'CI/CD',
      description: 'Proficiência em automação de processos de desenvolvimento',
      value: 82,
    },
    {
      title: 'Mobile Híbrido',
      description: 'Conhecimento em construção de aplicativos multiplataforma',
      value: 80,
    },
    {
      title: 'UI/UX',
      description: 'Habilidade em design centrado no usuário para melhor experiência',
      value: 88,
    },
]
export const TiagoSilvaLeftMolecule = () => {
    return(
        <>
            <TitleLineColors H1TitleEnginnerSofware={H1TitleEnginnerSofware} />
            <SeparetorMarginBottom />
            <Description {...description} />
            <SeparetorMarginBottom {...{value: 8}} />
            <ProgressPercentual props={progressPercentual} />
        </>
    )
}