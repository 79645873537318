import { Flex,Text } from "@chakra-ui/react"
import { ExperienceListProps } from "../../../interfaces/commom/interfaces"
import { theme } from "../../../shared/styles/theme"
import { H3Title } from "../../common/Title/H3Title"

export const ExperiencesMoecule = (props: ExperienceListProps) =>{
    return (
        <Flex flexDirection="column" textAlign="center">
            <Text fontSize="2xl" fontWeight="bold" color={theme.colors.primary[600]}>Total aproximado de</Text>
            <Text fontSize="96px" fontWeight="bold" color={theme.colors.primary[600]}>{props.hours}</Text>
            <H3Title {...{title: props?.title}} />
        </Flex>
    )
}