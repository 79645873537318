import Description from "../common/Description/Description";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import Title from "../common/Title/Title";
import { TitleLineColors } from "./TitleLineColors";

const H1TitleEnginnerSofware = { title: "Hobbies e Pets" };

export const HobbiesPetsDescriptionMolecule = () => {
  return (
    <>
      <TitleLineColors
        marginTop="4vw"
        H1TitleEnginnerSofware={H1TitleEnginnerSofware}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
      <Title {...{ title: "Hobbies:", as: 'h2', size: 'xl' }} />
      <SeparetorMarginBottom />
      <Description
        {...{
          description:
            "Minha principal distração do mundo real é o videogame, um velho amigo que sempre me proporciona momentos de diversão. Também aprecio assistir filmes, viajar para sítios e praias. Meu estilo musical favorito é o Lounge Music, que me ajuda a relaxar e criar um ambiente agradável. Sempre que possível, gosto de sair para festejar e celebrar com amigos e familiares.",
        }}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
      <Title {...{ title: "Pets:", as: 'h2', size: 'xl' }} />
      <SeparetorMarginBottom />
      <Description
        {...{
          description:
            "Tenho dois gatos, irmãos chamados Bob e Milo. Eles são meus inseparáveis companheiros de home office, sempre trazendo alegria com suas travessuras e, ocasionalmente, algumas mordidinhas nas canelas. Suas peripécias me ajudam a relaxar e a espairecer durante o dia.",
        }}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
    </>
  );
};
