import Description from "../../../../components/common/Description/Description";
import { SeparetorMarginBottom } from "../../../../components/common/SeparetorMarginBottom";
import Title from "../../../../components/common/Title/Title";
import { TitleLineColors } from "../../../../components/molecule/TitleLineColors";

export const FamilyLocationDescriptionMolecule = () => {
  return (
    <>
      <TitleLineColors
        marginTop="4vw"
        H1TitleEnginnerSofware={{ title: "Família e Localização" }}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
      <Title {...{ title: "Família:", as: 'h2', size: 'xl' }} />
      <SeparetorMarginBottom />
      <Description
        {...{
          description:
            "Sou pai de duas filhas maravilhosas que me inspiram diariamente. Elas são uma fonte constante de motivação e alegria, e me ajudam a manter um equilíbrio saudável entre a vida pessoal e profissional.",
        }}
      />
      <SeparetorMarginBottom {...{ value: 12 }} />
      <Title {...{ title: "Localização:", as: 'h2', size: 'xl' }} />
      <SeparetorMarginBottom />
      <Description {...{ description: "Atualmente, resido no Brasil." }} />
      <SeparetorMarginBottom {...{ value: 12 }} />
    </>
  );
};
