import { Flex, Text } from "@chakra-ui/react"

export const NumbersVerticaly = ({ props }: any) => {
    return(
        <Flex flexDirection="column" fontSize="3em" fontWeight="bold" color="black" width="30%" textAlign="center">
            <Text>0</Text>
            <Text>{props}</Text>
        </Flex>
    )
}

