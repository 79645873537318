import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Container, Flex, Grid, GridItem } from '@chakra-ui/react';
import { TitleLineColors } from '../molecule/TitleLineColors';
import { SeparetorMarginBottom } from '../common/SeparetorMarginBottom';
import { theme } from '../../shared/styles/theme';
import { H3Title } from '../common/Title/H3Title';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const BarChart = ({ ...optionsChatBar }: any) => {
    const h3Title: object = {
        title: `Comparação entre o Front e Back, em relação a 100% do tempo que foi empregado em desenvolvimento.`,
        color: theme.colors.default[900]
    }
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: `${optionsChatBar.datasets.year} -  Tempo em percentual(%) | Média diária de 10h(100%) do tempo designado.`,
            },
        },
    };

    const labels = optionsChatBar.labelsYears;

    const data = {
        labels,
        datasets: [
            {
                label: optionsChatBar.datasets.front.label,
                data: optionsChatBar.datasets.front.data,
                backgroundColor: optionsChatBar.datasets.front.backgroundColor,
            },
            {
                label: optionsChatBar.datasets.back.label,
                data: optionsChatBar.datasets.back.data,
                backgroundColor: optionsChatBar.datasets.back.backgroundColor,
            },
        ],
    };

    return (
        <Flex flexDirection="column" background={theme.colors.default[200]} padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Flex flexDirection="column" background={theme.colors.default[200]}>
                    <Grid templateColumns="reapeat(6, 1fr)" gap={69}>
                        <GridItem colSpan={2}>
                            <TitleLineColors
                                marginTop="4vw"
                                H1TitleEnginnerSofware={{ title: `Tempo Programação ${optionsChatBar.datasets.year}` }}
                            />
                            <SeparetorMarginBottom {...{ value: 12 }} />
                        </GridItem>
                        <GridItem colSpan={3} colEnd={6}>
                            <Flex alignItems="center" h="100%">
                                <H3Title {...h3Title} />
                            </Flex>
                        </GridItem>
                    </Grid>
                </Flex>
            </Container>
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Bar options={options} data={data} />
            </Container>
        </Flex>
    )
}