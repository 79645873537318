import { Flex } from "@chakra-ui/react"
import { H1LineColors } from "../common/H1LineColors/H1LineColors"
import Title from "../common/Title/Title"

export const TitleLineColors = (props: any) =>{
    return(
        <Flex flexDirection="column" marginTop={props.marginTop || '4vw'}>
            <H1LineColors />
            <Title {...props.H1TitleEnginnerSofware} />
        </Flex>
    )
}