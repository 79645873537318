import { Footer } from "../components/organism/Footer"
import { FormationTemplate } from "../components/template/FormationTemplate"

export const Formation = () => {
    return (
        <>
            <FormationTemplate />
            <Footer />
        </>
    )
}