import { HeaderTitle } from "../common/HeaderTitle"
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom"
import { FamilyLocationOrganism } from "../organism/FamilyLocationOrganism"
import { Header } from "../organism/Header"
import { HeaderNav } from "../organism/HeaderNav"
import { HobbiesPetsOrganism } from "../organism/HobbiesPetsOrganism"
import { PurposesOrganism } from "../organism/PurposesOrganism"
import { TiagoSilva } from "../organism/TiagoSilva"
import { Timeline } from "../organism/Timeline"
import { TitlePageORganism } from "../organism/TitlePageOrganism"

export const HomeTemplate = () => {
    const titlePage = {value: 'Apresentação'}
    return(
        <>
            <HeaderNav />
            <HeaderTitle {...titlePage} />
            <Header />
            <TitlePageORganism {...titlePage} />
            <TiagoSilva />
            <SeparetorMarginBottom {...{value: '108px'}} />
            <FamilyLocationOrganism />
            <HobbiesPetsOrganism />
            <Timeline />
            <PurposesOrganism />
        </>
    )
}