import { Flex, Text } from "@chakra-ui/react"
import { Images } from "../../../../components/common/Images"

import lizImage from './../../../../shared/images/liz.jpg';
import brasil from './../../../../shared/images/brasil.jpg';
import { ImageProps } from "../../../../interfaces/commom/interfaces";
import { H3Title } from "../../../../components/common/Title/H3Title";
import { theme } from "../../../../shared/styles/theme";

const srcBrasilImage: ImageProps = {
    src: brasil,
    alt: 'Imagem de Destaqu do Brasil'
}

const srcLizImage: ImageProps = {
    src: lizImage,
    alt: 'Imagem de Destaque'
}

export const FamilyLocationImagesMolecule = () => {
    return (
        <Flex>
            <Flex flex='1' border="1px solid" flexDirection="column" borderColor={theme.colors.tertiary[600]} >
                <Images {...srcBrasilImage} />
                <Text height="100%" background={theme.colors.tertiary[600]}>
                    <H3Title {...{ title: 'Brasil', color: 'white' }}></H3Title>
                </Text>
            </Flex>
            <Flex flex='1' border="1px solid" borderColor={theme.colors.tertiary[600]}>
                <Images {...srcLizImage} />
            </Flex>
        </Flex>
    )
}