import { Container, Flex, Grid, GridItem } from "@chakra-ui/react"
import { AbilityItensProps, AbilityProps } from "../../interfaces/commom/interfaces"
import { theme } from "../../shared/styles/theme"
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom"
import { ExperiencesAbilityListMolecule } from "../molecule/Experiences/ExperiencesAbilityListMolecule"
import Title from "../common/Title/Title"

interface rankAbility { front: AbilityProps[], back: AbilityProps[], mobile: AbilityProps[], pattern: AbilityProps[]};

enum rankEnum {
    rankAbilityFive = 5,
    rankAbilityFour = 4,
    rankAbilityTree = 3,
    rankAbilityTwo = 2,
    rankAbilityOne = 1
}

export const ExperiencesAbilityOrganism = () => {
    const rankAbility: rankAbility = {
        front: [
            { ability: 'HTML5 + SASS', rank: rankEnum.rankAbilityFive },
            { ability: 'Javascript (TS + ES6)', rank: rankEnum.rankAbilityFive },
            { ability: 'Angular', rank: rankEnum.rankAbilityFive },
            { ability: 'React', rank: rankEnum.rankAbilityFive },
            { ability: 'Vue', rank: rankEnum.rankAbilityTwo },
        ],
        back: [
            { ability: 'Python', rank: rankEnum.rankAbilityFour },
            { ability: 'SQL', rank: rankEnum.rankAbilityFour },
            { ability: 'Java', rank: rankEnum.rankAbilityTree },
            { ability: 'PHP', rank: rankEnum.rankAbilityTwo },
            { ability: 'NodeJs', rank: rankEnum.rankAbilityTwo },
        ],
        mobile: [
            { ability: 'React Native', rank: rankEnum.rankAbilityTree },
            { ability: 'Flutter', rank: rankEnum.rankAbilityTwo },
            { ability: 'Kotlin', rank: rankEnum.rankAbilityOne },
        ],
        pattern: [
            { ability: 'Kanban', rank: rankEnum.rankAbilityFive },
            { ability: 'Clean Code', rank: rankEnum.rankAbilityFive },
            { ability: 'Scrum', rank: rankEnum.rankAbilityFour },
            { ability: 'CI/CD', rank: rankEnum.rankAbilityFour },
            { ability: 'Designer Pattern', rank: rankEnum.rankAbilityFour },
        ]
    };

    const abilityItensData: any = {
        itens: [rankAbility.front, rankAbility.back, rankAbility.mobile, rankAbility.pattern]
    }

    return (
        <Flex flexDirection="column" background={theme.colors.default[200]} padding="9vw 0" bg={theme.colors.primary[600]}>
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Flex flexDirection="column">
                    <Title {...{ title: 'Habilidades', color: 'white', as: 'h1', size: '2xl' }} />
                    <SeparetorMarginBottom {...{ value: 12 }} />
                    <Grid templateColumns='repeat(4, 1fr)' gap={69} width="100%">
                        {abilityItensData.itens.map((element: AbilityItensProps, key: number) => (
                            <GridItem key={key} w='100%' height="100%">
                                <Flex height="100%">
                                    <ExperiencesAbilityListMolecule itens={element} />
                                </Flex>
                            </GridItem>
                        ))}
                    </Grid>
                </Flex>
            </Container>
        </Flex>
    )
}