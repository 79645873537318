import { Container, Flex, Grid, GridItem, ListItem, UnorderedList } from "@chakra-ui/react"
import { ExperienceListProps, ExperiencesDescriptionProps } from "../../interfaces/commom/interfaces"
import { theme } from "../../shared/styles/theme"
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom"
import { ExperiencesMoecule } from "../molecule/Experiences/ExperiencesHoursMolecule"
import { TitleLineColors } from "../molecule/TitleLineColors"

import moment from 'moment'
import 'moment-timezone'

export const ExperiencesDescriptionOrganism = () => {
    const today  = new Date().getFullYear();
    const startYears: any = {
        university: { year: 2007, hoursWork: 2, title: 'Horas nas universidades e cursos técnicos.' },
        develop: { year: 2011, hoursWork: 10, title: 'Horas de experiência como desenvolvedor Full Stack.' },
        ti: { year: 2005, hoursWork: 10, title: 'Horas atuando na área de T.I. Nas mais diversas áreas como: Redes, Manutenção de Hardware e Programação.' }
    }
    startYears.develop.diffYears = moment(today).diff(moment(startYears.develop.year));
    startYears.university.diffYears = moment(today).diff(moment(startYears.university.year));
    startYears.ti.diffYears = moment(today).diff(moment(startYears.ti.year));

    const totalHoursUniversityWork = startYears.university.hoursWork * 30 * 12 * startYears.university.diffYears / 2;
    const totalHoursDevelopWork = startYears.develop.hoursWork * 30 * 12 *  startYears.develop.diffYears;
    const totalHoursTyWork = startYears.ti.hoursWork * 30 * 12 *  startYears.ti.diffYears;

    const totalHoursUniversity = `${String(Math.round(totalHoursUniversityWork)).charAt(0)}`;
    const totalHoursDevelop = `${String(Math.round(totalHoursDevelopWork)).charAt(0)}${String(Math.round(totalHoursDevelopWork)).charAt(1)}`;
    const totalHoursTi = `${String(Math.round(totalHoursTyWork)).charAt(0)}${String(Math.round(totalHoursTyWork)).charAt(1)}`;

    startYears.university.hoursWorkTotal = `${totalHoursUniversity}k`;
    startYears.develop.hoursWorkTotal = `${totalHoursDevelop}k`;
    startYears.ti.hoursWorkTotal = `${totalHoursTi}k`;

    const experiencesDescriptionData: ExperiencesDescriptionProps = {
        list: [
            {item: 'Mais de 18 anos na área de T.I., sendo 12 anos de experiência como desenvolvedor.'},
            {item: 'Cursando 1º ano do curso de Engenharia de Software na USP/ESALQ.'},
            {item: 'Formado no curso de Sistemas para Internet na Faculdade FIAP.'},
            {item: 'Desenvolvedor Full Stack Kotlin, Angular, React, Vue, Java, .NET e PHP.'},
            {item: 'Diversas certificações de especialização técnica, nas escolas Impacta e Alura.'}
        ],
        experiences: [
            {title: startYears.university.title, hours: `${totalHoursUniversity}k`},
            {title: startYears.develop.title, hours: `${totalHoursDevelop}k`},
            {title: startYears.ti.title, hours: `${totalHoursTi}k`}
        ]
    } 

    return(
        <Flex flexDirection="column"  padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                 <Flex flexDirection="column">
                     <Grid templateColumns="reapeat(5, 1fr)" gap={69}>
                        <GridItem colSpan={1}>
                            <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={{ title: "Experiências" }} />
                            <SeparetorMarginBottom {...{value: 12}} />
                        </GridItem>
                        <GridItem colStart={2} colEnd={6}>
                            <Flex alignItems="center" h="100%">
                                <UnorderedList spacing={3} color={theme.colors.default[900]}>
                                    {experiencesDescriptionData.list.map((element: ExperienceListProps) => (
                                        <ListItem>
                                            {element.item}
                                        </ListItem>
                                    ))}
                                </UnorderedList>
                            </Flex>
                        </GridItem>
                    </Grid>
                    <SeparetorMarginBottom {...{value: 12}}/>
                    <Grid templateColumns='repeat(3, 1fr)' gap={69} width="100%">
                        {experiencesDescriptionData.experiences.map((element: ExperienceListProps, key: number) =>(
                            <GridItem key={key} w='100%' height="100%">
                                <Flex alignItems="center" height="100%">
                                    <ExperiencesMoecule {...element} />
                                </Flex>
                            </GridItem>
                        ))}
                    </Grid>
                </Flex>
            </Container>
        </Flex>
    )
}