import { Flex, Text, Progress, Divider, Box } from "@chakra-ui/react"
import { ReactElement } from "react"
import { ProgressPercentualProps } from "../../../../interfaces/commom/interfaces"
import { theme } from "../../../../shared/styles/theme"

export const ProgressPercentual = (props: any): ReactElement => {
  const progressPercentualData = {...props} 
    return(
      <>
        {progressPercentualData.props.map((element: ProgressPercentualProps, key: number) => (
          <Box key={key}>
            <Flex alignItems='center' fontWeight='bold'>
              <Text color={theme.colors.default[900]} fontSize='md' minWidth='25%' >{element.title}</Text>
              <Progress minWidth={{ base:'50vw', lg: '300px' }} minHeight='22px' value={element.value} margin='0px 14px' />
              <Text color={theme.colors.default[900]} fontSize='md' >{element.value}%</Text>
            </Flex>
            <Text color={theme.colors.default[900]} fontSize='md' margin="0px 10% 12px 0" >{element.description}</Text>
          </Box>
        ))}
      </>
    )
} 