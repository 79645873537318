import { Container, Flex, Text } from "@chakra-ui/react"
import { theme } from "../../shared/styles/theme"

import monitor from '../../shared/images/monitor.jpg'

export const TitlePageORganism = ({ ...props }: any) => {
    return (
        <Flex position="relative" flexDirection="column" background={`url(${monitor})`} padding="8vw 0" objectFit="cover" backgroundSize="cover" width="100%">
            <Flex zIndex="1" left="0" position="absolute" top="0" width="100%" height="100%" ></Flex>
            <Container position="relative" zIndex="2" maxW='1200px' color={theme.colors.default[100]}>
                <Text fontSize={{ base: '3em', md: "8em" }} textShadow="1px 1px 1px black" >
                    {props.value}
                </Text>
            </Container>
        </Flex>
    )
}