import React from 'react';
import { Flex } from '@chakra-ui/react';
import { theme } from '../../../shared/styles/theme';

type H1LineColorsProps = {};

export const H1LineColors: React.FC<H1LineColorsProps> = () => {
  return (
    <Flex width="140px" height="12px">
      <Flex
        data-testid="primary-bar"
        width="50%"
        bg={theme.colors.primary[600]}
      ></Flex>

      <Flex
        data-testid="second-bar"
        width="50%"
        bg={theme.colors.second[600]}
      ></Flex>
    </Flex>
  );
};
