import { Container, Flex } from "@chakra-ui/react";
import { HeaderTitle } from "../common/HeaderTitle";
import { APIViaCepMolecule } from "../molecule/APIViaCepMolecule";
import { HeaderNav } from "../organism/HeaderNav";
import { TitlePageORganism } from "../organism/TitlePageOrganism";
import { Footer } from "../organism/Footer";

export const AboutMeTemplate = () => {
  return (
    <>
      <HeaderNav />

      <HeaderTitle {...{ value: "Integração API ViaCEP" }} />
      <TitlePageORganism {...{ value: "API Via CEP" }} />

      <Flex flexDirection="column" padding="9vw 0">
        <Container maxW="1200px">
          <APIViaCepMolecule />
        </Container>
      </Flex>
      <Footer />
    </>
  );
};
