import { HeaderTitle } from "../common/HeaderTitle";
import { ExperiencesAbilityOrganism } from "../organism/ExperiencesAbilityOrganism";
import { ExperiencesCertificateOrganism } from "../organism/ExperiencesCertificateOrganism";

import { ExperiencesDescriptionOrganism } from "../organism/ExperiencesDescriptionOrganism";
import { ExperiencesProgrammingOrganism } from "../organism/ExperiencesProgrammingOrganism";
import { HeaderNav } from "../organism/HeaderNav";
import { TitlePageORganism } from "../organism/TitlePageOrganism";

export const ExperiencesTemplate = () => {
  return (
    <>
      <HeaderNav />
      <HeaderTitle {...{ value: "Experiências" }} />
      <TitlePageORganism {...{ value: "Experiências" }} />
      <ExperiencesDescriptionOrganism />
      <ExperiencesAbilityOrganism />
      <ExperiencesCertificateOrganism />
      <ExperiencesProgrammingOrganism />
    </>
  );
};
