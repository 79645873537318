import { BarChart } from "./BarChart"

type TlabelsMonth = 'Janeiro' | 'Feveriro' | 'Março' | 'Abril' | 'Maio' | 'Junho' | 'Julho' | 'Agosto' | 'Setembro' | 'Outubro' | 'Novembro' | 'Dezembro'
type TLabelBar = 'Front-end' | 'Back-end'

interface IDataSetsFrontBack {
    label: TLabelBar,
    data: number[],
    backgroundColor: string
}

interface IDataSetsBar {
    year: string,
    front: IDataSetsFrontBack,
    back: IDataSetsFrontBack
}

interface IDataSets {
    datasets: IDataSetsBar
}

export interface IDataOptionsChatBar {
    labels: TlabelsMonth[],
    years: IDataSets[]
}

export const ExperiencesProgrammingOrganism = () => {
    const optionsChatBar: IDataOptionsChatBar = {
        labels: ['Janeiro', 'Feveriro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        years: [
            {
                datasets: {
                    year: '2021',
                    front: {
                        label: 'Front-end',
                        data: [20, 20, 20, 35, 40, 40, 10, 0, 0, 20, 0, 10],
                        backgroundColor: 'rgba(34, 105, 153, 0.9)',
                    },
                    back: {
                        label: 'Back-end',
                        data: [80, 80, 80, 65, 60, 60, 90, 100, 100, 80, 100, 90],
                        backgroundColor: 'rgba(18, 153, 59, 0.9)',
                    }
                }
            },
            {
                datasets: {
                    year: '2022',
                    front: {
                        label: 'Front-end',
                        data: [80, 80, 80, 65, 60, 60, 90, 100, 100, 80, 100, 90],
                        backgroundColor: 'rgba(34, 105, 153, 0.9)',
                    },
                    back: {
                        label: 'Back-end',
                        data: [20, 20, 20, 35, 40, 40, 10, 0, 0, 20, 0, 10],
                        backgroundColor: 'rgba(18, 153, 59, 0.9)',
                    }
                }
            },
            {
                datasets: {
                    year: '2023',
                    front: {
                        label: 'Front-end',
                        data: [60, 65, 35, 70, 25, 50, 60, 40, 45, 50, 60, 70],
                        backgroundColor: 'rgba(34, 105, 153, 0.9)',
                    },
                    back: {
                        label: 'Back-end',
                        data: [40, 35, 65, 30, 75, 50, 40, 60, 55, 50, 40, 30],
                        backgroundColor: 'rgba(18, 153, 59, 0.9)',
                    }
                }
            },
            {
                datasets: {
                    year: '2024',
                    front: {
                        label: 'Front-end',
                        data: [45, 50, 80, 90, 50, 60, 0, 0, 0, 0],
                        backgroundColor: 'rgba(34, 105, 153, 0.9)',
                    },
                    back: {
                        label: 'Back-end',
                        data: [55, 50, 20, 10, 50, 40, 0, 0, 0, 0],
                        backgroundColor: 'rgba(18, 153, 59, 0.9)',
                    }
                }
            }
        ]
    }
    return (
        <>
            {optionsChatBar.years.map((element: IDataSets, key: number) => (
                <BarChart key={key} {...element} labelsYears={optionsChatBar.labels} />
            ))}
        </>
    )
}