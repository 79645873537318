import { ChakraProvider } from "@chakra-ui/react";

import { LightModeAtom } from "./components/common/LightMode";
import { theme } from "./shared/styles/theme";
import { RoutesDom } from "./routes/Routes";

import Axios from "axios";
import { configure } from "axios-hooks";
import CircuitBackground from "./components/organism/CircuitoBackground";

const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_VIA_CEP_URL}`,
});

configure({ axios });
export const App = () => (
  <ChakraProvider theme={theme}>
    <CircuitBackground />
    <LightModeAtom />
    <RoutesDom />
  </ChakraProvider>
);
