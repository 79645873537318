import { Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { ImageProps } from '../interfaces/commom/interfaces';
import coverageImg from '../shared/images/coverage.png';
import { theme } from '../shared/styles/theme';
import { Footer } from '../components/organism/Footer';
import { HeaderNav } from '../components/organism/HeaderNav';
import { HeaderTitle } from '../components/common/HeaderTitle';
import { TitlePageORganism } from '../components/organism/TitlePageOrganism';

const srcCoverage: ImageProps = {
  src: coverageImg,
  alt: 'Imagem de Destaque',
};

function CoveragePage() {
  return (
    <div>
      <HeaderNav />
      <TitlePageORganism {...{ value: "Teste unitário" }} />
      <Flex
        width="80%"
        justifyContent="center"
        background={theme.colors.default[100]}
      >
        <Image src={srcCoverage.src} alt={srcCoverage.alt} />
      </Flex>
      <Footer />
    </div>
  );
}

export default CoveragePage;
