import { Container, Flex } from "@chakra-ui/react";
import React from "react"
  import { Chrono } from "react-chrono";
import { TimelineItemModel } from "react-chrono/dist/models/TimelineItemModel";
import { theme } from "../../shared/styles/theme";
import { SeparetorMarginBottom } from "../common/SeparetorMarginBottom";
import { TitleLineColors } from "../molecule/TitleLineColors";

export const Timeline = () => {
    const items: TimelineItemModel[] = [
      {
        "title": "2008",
        "cardTitle": "Help Desk",
        "cardSubtitle": "Realização de manutenção de microcomputadores, abrangendo tanto hardware quanto software."
      },
      {
        "title": "2011",
        "cardTitle": "Desenvolvedor Full Stack",
        "cardSubtitle": "Início da carreira como desenvolvedor Full Stack com foco em PHP."
      },
      {
        "title": "2018",
        "cardTitle": "Desenvolvedor .NET",
        "cardSubtitle": "Primeira oportunidade como desenvolvedor .NET, trabalhando com ASP Clássico, VB e C#."
      },
      {
        "title": "2019",
        "cardTitle": "Single Page Application",
        "cardSubtitle": "Imersão em frameworks JavaScript: TypeScript, React, Angular, Vue, Next, e React Native."
      },
      {
        "title": "2021",
        "cardTitle": "FIAP",
        "cardSubtitle": "Formado em Sistemas para Internet, com foco na melhoria contínua de aplicações robustas e eficiência do sistema por meio de testes unitários."
      },
      {
        "title": "2023 - Atual",
        "cardTitle": "Banco do Brasil",
        "cardSubtitle": "Desenvolvimento de software utilizando Angular e Java."
      },
      {
        "title": "2024 - 2026",
        "cardTitle": "MBA USP/ESALQ",
        "cardSubtitle": "Cursando MBA em Engenharia de Software na USP/ESALQ, um centro de excelência e competência com grande destaque na produção de conhecimento."
      }
    ];
  
      return (
          <Flex width="100%" flexDirection="column" background={theme.colors.default[200]} padding="9vw 0">
            <Container maxW='1200px' color={theme.colors.default[100]} display="-webkit-inline-box">
                <Flex alignItems="center" height="100%">
                  <TitleLineColors marginTop="4vw" H1TitleEnginnerSofware={{ title: "História Pregressa" }} />
                  <SeparetorMarginBottom {...{value: 12}} />
                </Flex>
            </Container>
            <Container maxW='1200px' color={theme.colors.default[900]} display="-webkit-inline-box">
                <Flex width="100%" height={{ base: '80vw', md: '35vw' }} marginTop="4vw"  >
                  <Chrono enableOutline  items={items} />
                </Flex>
            </Container>
        </Flex>
      )
};