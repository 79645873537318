import React from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';
import { TitlePageORganism } from '../components/organism/TitlePageOrganism';
import { Footer } from '../components/organism/Footer';
import { HeaderNav } from '../components/organism/HeaderNav';

const NotFound = () => {
    return (
        <>
            <HeaderNav />
            <Flex
                height="85vh"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Text fontSize="6xl" fontWeight="bold">
                    404
                </Text>
                <Text fontSize="2xl" fontWeight="bold" mt={2}>
                    Página não encontrada
                </Text>
                <Box mt={4}>
                    <Button colorScheme="blue" onClick={() => window.history.back()}>
                        Voltar
                    </Button>
                </Box>
            </Flex >
            <Footer />
        </>
    );
};

export default NotFound;
